<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-21 19:42:31
 * @LastEditors: 王鹏
 * @LastEditTime: 2020-02-26 00:42:37
 -->
<template>
  <div class="mainbody" :style="{height: conheight+'px',minHeight:conheight+'px'}">
    <div class="diabg" v-if="showdialog"></div>
    <div class="dialog" v-if="showdialog" >
      <div class="dialog_con">
        <div class="addfamins" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).addjtbg+');background-size: 102% 100%'">
          <p class="addinfo">添加家庭信息</p>
        </div>
        <p>请填写该家庭的名称</p>
        <input type="text" class="inputname" v-model="familyname" @blur="onblur" placeholder="请输入家庭名称" />
        <md-button :style="'background:'+getStorage('theme','')+'!important'" type="primary" :loading="submitnameloading" @click="savefamily">保存</md-button>
      </div>
      <img
        src="@/assets/abd/image/closedialog.png"
        class="closedialog"
        @click="closedialog"
      />
    </div>
    <!-- 可见区域 -->
    <div class="title">
      <div class="left" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).jtnumbg+');background-size: 100% 100%;'">
        <div class="userleft">
          <p>家庭数量</p>
          <p>{{ customcount }}</p>
        </div>
        <div class="userright">
          <img src="@/assets/abd/image/SAAS-61.png" alt="" />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="bodytitle">
        <p>家庭列表</p>
        <!-- <img
          src="@/assets/abd/image/main-refresh.png"
          class="refreshbtn"
          @click="refreshfamilylist"
        /> -->
        <img :src="getStorage('ossurl','')+getStorage('imgs',{}).refreshbtn" class="refreshbtn" @click="refreshfamilylist"/>
        <img src="@/assets/abd/image/SAAS-64.png" alt="" @click="showadd" />
      </div>
       <div class="bodylist">
        <md-scroll-view
          ref="scrollView"
          :scrolling-x="false"
          :auto-reflow="true"
          @refreshing="$_onRefresh"
          @end-reached="$_onEndReached">
           <md-scroll-view-refresh
            slot="refresh"
            slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
            :scroll-top="scrollTop"
            :is-refreshing="isRefreshing"
            :is-refresh-active="isRefreshActive"
            :refreshing-text="'拼命加载中...'"
            ></md-scroll-view-refresh>
           <div>
            <div class="list" v-for="i in famliyList" :key="i.familyno">
            <div class="listleft">
              <img src="@/assets/abd/image/SAAS-76.png" alt="" />
            </div>
            <div class="listright" @click="todetails(i.familyname, i.familyno,i.comid,i.empno)">
              <div>
                <div class="name">
                  <p>{{ i.familyname }}</p>
                  <div class="twopic">
                    <!-- <img
                      src="@/assets/abd/image/timg.jpg"
                      alt=""
                      @click.stop="showtwopic"
                      style="width:5vh;height:5vh"
                    /> -->

                    <span>{{ i.intime }}</span>
                  </div>
                </div>
              </div>
              <img
                src="@/assets/abd/image/SAAS-65.png"
                alt=""
                style="width:2vw;height:3vw"
              />
             </div>
            </div>
            </div>
           <md-scroll-view-more
            slot="more"
            :is-finished="isFinished"
           ></md-scroll-view-more>
       </md-scroll-view>
        </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mand-mobile'
import { famliyList, addFamliy } from '@/api/nbs/nbsinfo/index'
import { compatibleFixed, getStorage } from '@/lib/util'
export default {
  data () {
    return {
      submitnameloading: false,
      showdialog: false,
      famliyList: [],
      showaddflag: false,
      familyname: '',
      customcount: '',
      policycount: '',
      winHeight: 0,
      // 列表分页
      page: 1,
      size: 50,
      total: 0,
      isFinished: false,
      isAndroid: true,
      conheight:''
    }
  },
  watch: {
    showaddflag (newval) {
      if (newval === false) this.familyname = ''
    }
  },
  created () {
    this.getData(50, 1)
    this.conheight = document.documentElement.clientHeight
    // this.conheight = window.innerHeight
    console.log(this.conheight)
    // 在页面整体加载完毕时
    window.onload = () => {
      // 把获取到的高赋值给mainbody
      document.getElementsByClassName('mainbody')[0].style.height = `${this.conheight}px`
      document.body.style.height = `${this.conheight}px`
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      this.isAndroid = false
    }
    // 获取可视区域的高度
    // let conheight = document.documentElement.clientHeight
    // // 在页面整体加载完毕时
    // window.onload = () => {
    //   // 把获取到的高赋值给mainbody
    //   document.getElementByclassName('mainbody').style.height = `${conheight}px`
    // }
    // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //   // ios
    //   this.isAndroid = false
    // }
  },
  mounted () {
    window.ScrollViewTrigger1 = () => {
      this.$refs.scrollView.triggerRefresh()
    }
    // $(document).on('focusout', function () {
    //   // 软键盘收起的事件处理
    //   window.scroll(0, 0)
    // })
    this.conheight = document.documentElement.clientHeight
    // this.winHeight = document.body.clientHeight // 获取当前页面高度
    // let that = this
    // // $(window).resize(function () {
    // //   // alert(that.winHeight)
    // //   // alert(document.body.clientHeight)
    // //   document.body.clientHeight = that.winHeight
    // // })
    // $(document).ready(function () {
    //   $('body').height($('body')[0].clientHeight)
    // })
    // compatibleFixed()
  },
  methods: {
    // 获取列表数据
    async getData (size, page) {
      await famliyList({ size, page }).then(res => {
        this.famliyList = res.data.data.list
        this.customcount = res.data.data.countFamily
        this.policycount = res.data.data.countFamilyPolicy
        this.total = res.data.data.countFamily
      })
    },
    // 下拉刷新
    $_onRefresh () {
      this.getData(50, 1)
      this.page = 1
      this.size = 50
      setTimeout(() => {
        this.$refs.scrollView.finishRefresh()
        this.isFinished = false
        this.$refs.scrollView.finishLoadMore()
      }, 1000)
    },
    async $_onEndReached () {
      if (this.isFinished) {
        return
      }
      let list = [...this.famliyList]
      this.page = this.page + 1
      if (this.total > (this.page - 1) * this.size && this.total < this.page * this.size) { await this.getData(this.size, this.page); this.famliyList = [...list, ...this.famliyList]; if (this.famliyList.length === this.total) this.isFinished = true } else if (this.page * this.size >= this.total) { this.isFinished = true; this.$refs.scrollView.finishLoadMore() } else { await this.getData(this.size, this.page); this.famliyList = [...list, ...this.famliyList]; if (this.famliyList.length === this.total) this.isFinished = true }
    },
    async refreshfamilylist () {
      Toast.loading('刷新中...')
      await this.getData(50, 1)
      Toast.succeed('刷新成功！')
    },
    showadd (e) {
      e.stopPropagation()
      this.familyname = ''
      this.showdialog = true
    },
    savefamily () {
      this.submitnameloading = true
      addFamliy({ familyname: this.familyname })
        .then(res => {
          this.showdialog = false
          Toast.succeed('保存成功')
          // famliyList({ name: '' }).then(res => {
          //   this.famliyList = res.data.data.list
          //   this.num1 = res.data.data.countFamily
          //   this.num2 = res.data.data.countFamilyPolicy
          // })
        }).finally(() => {
          this.getData(50, 1)
          this.submitnameloading = false
        })
    },
    todetails (name, no, comid, empno) {
      this.$router.push({
        path: '/nbsReport',
        query: { familyname: name, familyno: no, comid: comid, empno: empno }
      })
    },
    closedialog () {
      this.showdialog = false
    },
    onblur () {
      window.scroll(0, 0)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .md-button{
    background-color #00ADEF
  }
  .mainbody{
    background-color  #F7F6FB
    width 100vw
    height 100%
    overflow hidden
    position absolute 
  }
  .title{
    display flex
    align-items center
    width 100vw
    height 20vh
    background-color white
    .left{
      display flex
      justify-content space-between
      align-items center
      width 90%
      padding 0 4%
      margin-left 4.5vw
      margin-right 1.5vw
      height 10vh
      background-color pink
      border-radius 10px
      // background: url('~@/assets/abd/image/SAAS-60.png') no-repeat;
      background-size: 100% 100%;
      .userleft{
        width 70%
        margin-left 10%
        p{
          font-size 45px
          color white
        }
        p:nth-child(1){
          font-size 34px
          color #F2F9FD
          margin-bottom 1vw
        }
      }
      .userright{
        width 50%
        display flex
        justify-content center
        img{
          width 7vw
        }
      }
    }
    .right{
      display flex
      justify-content space-between
      align-items center
      border-radius 10px
      margin-left 1.5vw
      background-color orange
      width 44%
      padding 0 4%
      height 10vh
      background: url('~@/assets/abd/image/SAAS-60.png') no-repeat;
      background-size: 100% 100%;
      .policyleft{
        width 70%
        margin-left 10%
        p{
          font-size 45px
          color white
        }
        p:nth-child(1){
          display flex
          align-items center
          font-size 34px
          color #F2F9FD
          margin-bottom 1vw
          img{
            margin-left 15px
          }
        }

      }
      .policyright{
        width 50%
        display flex
        justify-content center
      }
    }
  }
  .body{
    background-color white
    padding 0 4.5vw
    margin-top 20px
    .bodytitle{
      position relative
      height 12vh
      display flex
      justify-content space-between
      align-items center
      padding-top 30px
      border-bottom 1px solid #F7F6FB
      p{
        font-size 38px
        font-family 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
        font-weight bold
        color #4B4B4B
        letter-spacing:4px
      }
      .refreshbtn{
        width 14vw
        height 14vw
        position absolute
        left 38vw
      }
      img{
        width 5vw
        height 5vw
      }
    }
    .bodylist{
      height 65vh
      overflow auto
    }
    .list{
      display flex
      height 14vh
      align-items center
      border-bottom 1px solid #F4F4F4
      .listleft{
        width 20vw
        img{
          width 10vh
          height 10vh
          border-radius 50%
        }
      }
      .listright{
        width 78vw
        display flex
        height 100%
        justify-content space-between
        align-items center
        .name{
          font-size 38px
          /* margin-bottom 30px */
          display flex
          justify-content space-between
          align-items center
          width 68vw
          span{
            color #C1C3C5
            font-size 32px
          }
        }
        .detail{
          span{
            color #757575
            font-size 32px
          }
          span:nth-child(1){
            margin-right 50px
          }
        }
      }
    }
  }
  .diabg{
    position:absolute
    z-index 111
    background-color black
    opacity 0.6
    width 100vw
    height 100%
    overflow-y hidden
}
.dialog{
  position absolute
  width 80vw
  height 600px
  z-index 120
  border-radius 27px
  background-color white
  top 200px
  left 10vw
  .addfamins{
      display flex
      justify-content center
      height 200px
      width 100%
      border-radius 10px 23px 0 0
      /*background: url('~@/assets/abd/image/addfamname.png') no-repeat;*/
      background-size: 102% 100%;
      p{
        font-family:PingFang SC;
        color:rgba(255,255,255,1);
        font-size 56px
        letter-spacing:12px
      }
    }
   p{
      font-family:PingFang SC;
      color:rgba(117,117,117,1);
      padding 25px 8vw 20px
    }
    /deep/ .md-button{
        background-color color-primary
        height 100px
        border-radius 8px
        width 90%
        margin 20px auto
        color white
        display flex
        justify-content center
        align-items center
    }
   /deep/ .md-input-item-input{
        width 80%
        margin 0 10%
        background-color #F5F5F5
        font-size 35px
        padding 0 30px
    }
    .inputname{
        display block
        width 80%
        height 100px
        line-height 100px
        margin 0 10%
        background-color #F5F5F5
        font-size 35px
        padding 0 30px
        border-radius 10px
      }
       .inputname::-webkit-input-placeholder { /* WebKit browsers */
       color: #ced0d6;
       font-family:PingFang SC;
       color:rgba(157,157,157,1);
       font-weight:500;
       font-size 35px
    }
    .closedialog{
      position absolute
      z-index 121
      margin-top 200px
      width 80px
      height 80px
      left 36vw
     }
}
</style>
